import { URL } from "@/api/invoices";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import InputMoney from "@/components/inputMoney.vue";
import formatDate from "@/plugins/formatDate.js";
import formatNumber from "@/plugins/formatNumber.js";
import searchInput from "@/components/searchInput";
import loadingInvoice from "@/components/loadingInvoice";
import CursorPaginate from "@/components/CursorPaginate.vue";
import { labelBackgroundStyle, buildColors, hashCode } from "../../../plugins/common";

export default {
  components: {
    InputMoney,
    searchInput,
    loadingInvoice,
    CursorPaginate,
  },
  data() {
    return {
      showTable: true,
      activeInvoice: "all",
      dataInvoices: [],
      showAddModal: false,
      showEditModal: false,
      addTargetInvoices: {},
      editTargetInvoices: {},
      files: [],
      dialogWidth: "50%",
      search: "",
      indexRole: null,
      dataPaginate: {
        subtotal: {}
      },
      paginate: {
        limit: 20,
        currentPage: 1,
        cursor: null,
        next: null,
        prev: null,
      },
      params: {
        key_search: null,
      },
      totalFileSize: 0,
      isBigSize: false,
      isLoading: false,
      labeled: false,
      replacedInvoice: false,
      deletedInvoice: false,
      warningCompany: false,
      rateError: false,
      tmpStore: [],
      bottom_position: 0,
      advanceSearch: false,
      scrollPosition: 0
    };
  },

  methods: {
    ...mapActions(["loadingLayer"]),

    async getListInvoices() {
      if (!this.selectCurrentCompany) {
        return;
      }
      this.params.key_search = null;
      if (this.search) {
        this.params.key_search = this.search;
      }
      this.params.rateError = this.rateError;
      this.params.labeled = this.labeled;
      this.params.replacedInvoice = this.replacedInvoice;
      this.params.deletedInvoice = this.deletedInvoice;
      this.params.warningCompany = this.warningCompany;
      this.params.show = this.activeInvoice;
      this.params.company_id = this.selectCurrentCompany;

      this.params.limit = this.paginate.limit;
      this.params.cursor = this.paginate.cursor;
      this.params.order_by = this.getInvoiceSort.prop;
      this.params.order_type = this.getInvoiceSort.order;

      if (this.getInvoiceSort.order &&
        this.getInvoiceSort.prop &&
          ["seller_status", "lookupinfo__invoice_status"].indexOf(this.getInvoiceSort.prop) == -1
      ) {
        this.params.order_type = this.getInvoiceSort.order.slice(0, 3);
      }

      // GET data from cache
      const hash = hashCode(this.params);
      if (this.getInvoiceCache && this.getInvoiceCache.hash == hash) {
        this.handleLazyLoadInvoices(this.getInvoiceCache.value, this.paginate.scrollPosition);
        return;
      }

      this.isLoading = true;
      const result = await this.$request({
        url: URL.GET_INVOICES,
        params: this.params,
      });
      const res = result.data;
      this.isLoading = false;

      if (res.code == 200) {
        this.$store.dispatch("setInvoiceCache", {
          hash,
          value: res.data.data
        })
        this.$store.dispatch("setIsUpdating", res.data.is_updating);
        this.$store.dispatch("setInvoices", res.data.data);
        this.dataPaginate = res.data;
        this.paginate = JSON.parse(JSON.stringify(this.paginate));
        this.paginate.prev = res.data.prev;
        this.paginate.next = res.data.next;
        this.$store.dispatch("setInvoicePaginate", this.paginate);
        this.handleLazyLoadInvoices(res.data.data);
      }
    },

    handleLazyLoadInvoices(data, scrollPosition=null) {
      const numberOfSlices = scrollPosition ? 100 : 30 ;
      // this.showTable = false;
      this.$nextTick(() => {
        // this.showTable = true
        if (scrollPosition) {
          this.$nextTick(() => {
            this.$refs.tableScroll.setScrollTop(scrollPosition.scrollTop)
          });
        }
      });
      this.dataInvoices = data.slice(0, numberOfSlices);
      this.tmpStore = data;
    },

    handleScroll (e) {
      this.scrollPosition = e;
      this.dataInvoices = this.tmpStore
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
    },

    handleCursorChange($event) {
      this.paginate.cursor = $event;
      this.getListInvoices();
    },

    handleCheckIssueDateInRangeOfReportPeriod (invoice) {
      let d1 = new Date(invoice.issued_date);
      let d2 = new Date(invoice.report_period_date);
      let y1, y2, q, compare = null;

      y1 = d1.getFullYear();
      y2 = d2.getFullYear();
      q = d1.getMonth();
      compare = d2.getMonth();
      if (this.declarationType == "Q") {
        q = Math.ceil((q + 1) / 3);
        compare = Math.ceil((compare + 1) / 3);
      }
      return q === compare && y1 === y2
    },

    handleLimitChange(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.paginate.cursor = null;
      this.paginate.next = null;
      this.paginate.prev = null;
      this.getListInvoices();
    },


    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    clickRow(obj) {
      this.$router.push({
        name: "editInvoices",
        params: { invoiceId: obj.id },
      });
    },

    searchInput() {
      this.paginate.totalPage = 1;
      this.paginate.cursor = null;
      this.paginate.next = null;
      this.paginate.prev = null;
      this.$store.dispatch("setInvoiceKeySearch", this.search);
      this.getListInvoices();
    },

    focusInput() {
      const input = document.getElementById("input-search");
      input.focus();
      input.select();
    },

    openEditModal(data) {
      this.showEditModal = true;
      this.editTargetInvoices = _.cloneDeep(data);
    },

    openCreateModal() {
      this.showAddModal = true;
    },

    uploadFile(e) {
      let array = e.target.files;
      this.calSizeFiles(array);
    },

    dragFile(e) {
      let array = e.dataTransfer.files;
      this.calSizeFiles(array);
    },

    calSizeFiles(array) {
      let size = 0;
      let temp = [];
      Array.from(array).forEach((item) => {
        let fileInv = item.name.substr(-4);
        size += item.size;
        if (
          item.type == "text/xml" ||
          item.type == "application/pdf" ||
          item.type == "application/zip" ||
          item.type == "application/vnd.rar" ||
          fileInv == ".inv"
        ) {
          temp.push(item);
        }
      });
      this.files = temp;
      this.totalFileSize = parseFloat(size / 10 ** 6).toFixed(3);
      this.isBigSize = this.totalFileSize >= 20;
    },

    async upload() {
      this.loadingLayer(true);
      let formData = new FormData();
      formData.append("company_id", this.selectCurrentCompany);
      _.forEach(this.files, (file) => {
        formData.append("files", file);
      });

      const result = await this.$request({
        url: URL.ADD_INVOICES.replace(":id", this.selectCurrentCompany),
        method: "POST",
        data: formData,
      });
      const res = result.data;

      if (res.code == 200 && res.success) {
        this.$swal({
          text: res.messages,
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
        this.showAddModal = false;
        this.getListInvoices();
      }

      if (!res.success) {
        this.$swal({
          text: res.messages,
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
      this.loadingLayer(false);
    },

    resetAddInvoices() {
      this.totalFileSize = 0;
      this.isBigSize = false;
      document.getElementById("clickChoseFile").value = "";
      this.files = [];
    },

    deleteFile($event, index) {
      $event.preventDefault();
      let fileArray = Array.from(this.files);
      document.getElementById("clickChoseFile").value = "";
      fileArray.splice(index, 1);
      this.files = fileArray;
      this.calSizeFiles(this.files);
    },

    disableOpenAddFile($event) {
      $event.preventDefault();
    },

    sliceStr(str) {
      if (str.length > 80) {
        return str.substr(0, 75) + "..." + str.substr(-5);
      }
      return str;
    },

    changeDeclarationType(id, value) {
      this.$swal({
        title: "Thay đổi kỳ kê khai?",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {};
          data.report_period = value;
          const result = await this.$request({
            url: URL.UPDATE_INVOICE.replace(":id", id),
            method: "PUT",
            data,
          });
          const res = result.data;

          if (res.code == 200) {
            this.$swal({
              text: "Cập nhật thành công",
              icon: "success",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
          }
        }
        this.getListInvoices();
      });
    },

    setDialogWidth() {
      let windowSize = document.body.clientWidth;
      if (windowSize < 767) {
        this.dialogWidth = "90%";
      } else {
        this.dialogWidth = "50%";
      }
    },

    changeInvoice() {
      this.paginate.currentPage = 1;
      this.paginate.cursor = null;
      this.paginate.next = null;
      this.paginate.prev = null;
      this.getListInvoices();
      this.$store.dispatch("setActiveInvoice", this.activeInvoice);
    },

    sortChange(sortProps) {
      this.$store.dispatch("setInvoiceSort", sortProps);
      this.params.order_by = sortProps.prop;
      if (sortProps.order) this.params.order_type = sortProps.order.slice(0, 3);
      else this.params.order_type = null;
      this.getListInvoices();
    },

    headerClick (header) {
      if (header.label == "TT NCC" || header.label == "TT HD") {
        let prop = 'seller_status'
        if (header.label == "TT HD") {
          prop = 'lookupinfo__invoice_status'
        }
        // Order type: 1 - green, 2 - yellow, 3 - red
        this.$refs.tableData.clearSort();
        let orderValue = parseInt(this.getInvoiceSort.order) || 0;
        if (this.getInvoiceSort.prop != prop) {
          orderValue = 0;
        }
        if (++orderValue > 3) {
          this.$store.dispatch("setInvoiceSort", {
            order: null,
            prop: null,
          });
        } else {
          this.$store.dispatch("setInvoiceSort", {
            order: orderValue,
            prop,
          });
        }
        this.getListInvoices();
      }
    },
    labelBackgroundStyle,
    buildColors,
    formatDate,
    formatNumber,
    formatCurrency(number) {
      return new Intl.NumberFormat("de-DE").format(number);
    },
  },

  created() {
    let value = this.$store.state;
    if (value) {
      this.search = value.keepSearchInvoice.keySearch;
      this.indexRole = value.setRoleUser.indexRole;
    }
    this.paginate = this.getInvoicePaginate;
    this.activeInvoice = this.getActiveInvoice;
    this.getListInvoices();
    this.setDialogWidth();
  },

  mounted() {
    
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  computed: {
    ...mapGetters([
      "selectCurrentCompany",
      "declarationType",
      "getInvoiceSort",
      "getInvoicePaginate",
      "getActiveInvoice",
      "getInvoiceCache",
    ]),
    subTotal() {
      return this.tmpStore.reduce((total, invoice) => total + invoice.amount_included_vat, 0)
    }
  },

  watch: {
    selectCurrentCompany() {
      this.paginate = {
        ...this.paginate,
        currentPage: 1,
        cursor: null,
        prev: null,
        next: null
      };
      this.getListInvoices();
    },
  },
  beforeRouteLeave() {
    this.$store.dispatch("setInvoicePaginate", {
      ...this.paginate,
      scrollPosition: this.scrollPosition
    });
  },
};
