<div class="block justify-between md:flex items-center mb-2">
  <div>
    <el-tabs v-model="activeInvoice" @tab-click="changeInvoice">
      <el-tab-pane label="Tổng hợp" name="all" class="w-full" />
      <el-tab-pane label="Hóa đơn đầu ra" name="in" class="w-full" :disabled="selectCurrentCompany == -1"/>
      <el-tab-pane label="Hóa đơn đầu vào" name="out" class="w-full" :disabled="selectCurrentCompany == -1"/>
    </el-tabs>
  </div>
  <div class="text-right mt-2 flex justify-end md:mt-0">
    <el-button
      size="mini"
      type="primary"
      icon="el-icon-plus"
      @click="openCreateModal()"
    >
      Thêm hóa đơn
    </el-button>
  </div>
</div>

<el-scrollbar class="height-table" v-loading="isLoading" @scroll="handleScroll" ref="tableScroll">
  <el-table
    v-if="showTable"
    :data="dataInvoices"
    border
    stripe
    style="width: 100%"
    empty-text="Không có dữ liệu"
    :default-sort="{ prop: getInvoiceSort.prop, order: getInvoiceSort.order }"
    @sort-change="sortChange"
    :header-cell-style="{ background: 'white' }"
    ref="tableData"

    @row-click="clickRow($event)"
    @header-click="headerClick($event)"
  >
    <el-table-column label="Delivery Info" align="right">
      <template #header>
        <div class="flex justify-between">
          <div class="flex">
            <search-input
              v-model="search"
              class="w-96"
              :isIcon="true"
              placeholder="Tìm kiếm theo số hóa đơn hoặc nhà cung cấp"
              @inputSearch="search = $event"
              @doneTyping="searchInput()"
            />
            <div class="flex items-center gap-2 ml-2">
              <el-popover :visible="advanceSearch" placement="bottom" :width="300">
                <div class="items-center gap-2 ml-2">
                  <el-checkbox v-model="labeled" @change="advanceSearch=false;getListInvoices()"> Hóa đơn có nhãn</el-checkbox>
                  <el-checkbox v-model="rateError" @change="advanceSearch=false;getListInvoices()">Hóa đơn lỗi tỷ giá</el-checkbox>
                  <el-checkbox v-model="deletedInvoice" @change="advanceSearch=false;getListInvoices()">Hóa đơn huỷ/xoá/bị thay thế</el-checkbox>
                  <el-checkbox v-model="replacedInvoice" @change="advanceSearch=false;getListInvoices()">Hóa đơn thay thế</el-checkbox>
                  <el-checkbox v-model="warningCompany" @change="advanceSearch=false;getListInvoices()">Công ty có tình trạng bị cảnh báo</el-checkbox>
                </div>
                <hr>
                <div class="text-right mt-2">
                  <el-button size="mini" @click="advanceSearch=false">Đóng</el-button>
                </div>
                <template #reference>
                  <div>
                    <el-badge :is-dot="rateError || labeled || deletedInvoice || replacedInvoice || warningCompany">
                      <el-button @click="advanceSearch=true" icon="el-icon-setting" type="secondary" size="small">Tìm kiếm nâng cao</el-button>
                    </el-badge>
                  </div>
                </template>
              </el-popover>
            </div>

          </div>
          <div class="flex justify-between items-center custom-margin" v-show="activeInvoice != 'all'">
            <p class="mr-2">Tổng tiền </p>
            <input-money
              v-model="subTotal"
              class="money subtotal"
              :table="true"
              :decima="0"
              :disabled="true"
            />
          </div>
        </div>
      </template>

      <el-table-column
        type="index"
        label="STT"
        :index="indexMethod"
        max-width="150"
        width="80"
        min-width="50"
        align="center"
      />

      <el-table-column
        prop="report_period"
        sortable="custom"
        label="Kỳ kê khai"
        width="180"
        align="center"
      >
        <template #default="scope">
          <div class="select-declaration flex items-center">
            <el-tooltip
              v-if="scope.row.labels && scope.row.labels.labels"
              class="item absolute h-full left-0"
              effect="dark"
              placement="bottom"
            >
              <template #content>
                <div v-for="label in scope.row.labels.labels" :style="`background: ${label.label__color}`" class="px-3 py-1 labelPreview mb-1 text-center rounded">
                  <div class="inline-block truncate">{{ label.label__name }}</div>
                </div>
              </template>
              <div class="w-4" :style="labelBackgroundStyle(scope.row.labels.colors)">&nbsp;</div>
            </el-tooltip>

            <div class="text-red-500" v-if="!handleCheckIssueDateInRangeOfReportPeriod(scope.row)">*</div>
            <el-select
              class="w-full"
              :class="handleCheckIssueDateInRangeOfReportPeriod(scope.row) ? 'fix-width' : ''"
              v-model="scope.row.report_period"
              placeholder="Select"
              @change="changeDeclarationType(scope.row.id, scope.row.report_period)"
              :disabled="scope.row.is_report"
            >
              <el-option
                v-for="(item, index) in scope.row.periods"
                :key="index"
                :value="item"
              >
                {{item}}
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable="custom"
        prop="number"
        label="Số HD"
        align="center"
        width="110"
      />

      <el-table-column
        sortable="custom"
        prop="issued_date"
        label="Ngày PH"
        align="center"
        width="110"
      >
        <template #default="props">
          <div>{{formatDate(props.row.issued_date)}}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="seller_name"
        sortable="custom"
        label="Nhà cung cấp"
        min-width="300"
      >
        <template #default="props">
          <div class="flex justify-between items-center">
          <div>{{props.row.seller_name}}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="seller_status"
        label="TT NCC"
        align="center"
        width="100"
      >
        <template #header>
          TT NCC
          <img
              v-if="getInvoiceSort.prop == 'seller_status'"
              src="@/assets/icons/icon_check.svg"
              class="w-4 h-4 fill-color inline-block"
              :class="{
                'fill-color-red': getInvoiceSort.order == 3,
                'fill-color-orange': getInvoiceSort.order == 2,
              }"
          />
        </template>
        <template #default="props">
          <el-tooltip
            class="item ml-2"
            effect="dark"
            :content="props.row.seller_status"
            placement="left"
          >
          <div class="text-center">
            <img
                v-if="props.row.seller_status"
                src="@/assets/icons/icon_check.svg"
                alt=""
                class="w-4 h-4 fill-color inline-block"
                :class="{
                  'fill-color-red': 
                    props.row.seller_status.includes('ngừng') ||
                    props.row.seller_status.includes('nghỉ') ||
                    props.row.seller_status.includes('không') ||
                    props.row.seller_status.includes('dừng')
                }"
            />
            <img
                v-else
                src="@/assets/icons/icon_check.svg"
                alt=""
                class="w-4 h-4 fill-color fill-color-orange inline-block"
            />
          </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        prop="lookupinfo__invoice_status"
        label="TT HD"
        align="center"
        width="100"
      >
        <template #header>TT HĐ
          <img
              v-if="getInvoiceSort.prop == 'lookupinfo__invoice_status'"
              src="@/assets/icons/icon_check.svg"
              class="ml-1 w-4 h-4 fill-color inline-block"
              :class="{
                'fill-color-red': getInvoiceSort.order == 3,
                'fill-color-orange': getInvoiceSort.order == 2,
              }"
          />
        </template>
        <template #default="props">
          <el-tooltip
            class="item w-20"
            effect="dark"
            placement="right"
          >
            <template #content>
              <div class="w-80" v-html="props.row.lookupinfo ? props.row.lookupinfo.invoice_note : '' "></div>
            </template>
          <div class="text-center">
            <img
                v-if="props.row.lookupinfo && props.row.lookupinfo.invoice_status"
                src="@/assets/icons/icon_check.svg"
                alt=""
                class="w-4 h-4 fill-color inline-block"
                :class="{
                  'fill-color-red': props.row.lookupinfo.invoice_status == 2
                }"
            />
            <img
                v-else
                src="@/assets/icons/icon_check.svg"
                alt=""
                class="w-4 h-4 fill-color fill-color-orange inline-block"
            />
          </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        prop="buyer_name"
        sortable="custom"
        label="Người mua"
        min-width="280"
      />

      <el-table-column
        sortable="custom"
        prop="amount_included_vat"
        label="Số tiền"
        align="right"
        width="200"
      >
        <template #default="props">
          <div class="money-row">
            <input-money
              v-model.lazy="props.row.amount_included_vat"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="currency"
        sortable="custom"
        label="Tiền tệ"
        align="center"
        width="100"
      >
        <template #default="props">
          {{ props.row.currency }}
          <div v-if="props.row.currency != 'VND'">
            {{ formatCurrency(props.row.ti_gia) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="" width="100" align="center">
        <template #header> Thao tác </template>
        <template #default="scope">
          <el-button size="mini" type="primary" icon="el-icon-edit" circle />
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</el-scrollbar>

<div>
  <cursor-paginate
    :currentPage="paginate.currentPage"
    :limit="paginate.limit"
    :next="paginate.next"
    :prev="paginate.prev"
    @update:limit="handleLimitChange"
    @update:currentPage="handleCurrentPageChange($event)"
    @update:cursor="handleCursorChange($event)"
  />
</div>

<el-dialog
  title="Chỉnh sửa Invoices"
  v-model="showEditModal"
  :width="dialogWidth"
>
  <el-form ref="form" :model="form" label-width="120px">
    <el-form-item label="Kỳ kê khai">
      <el-input v-model="editTargetInvoices.report_period"></el-input>
    </el-form-item>
    <el-form-item label="Ngày phát hành">
      <el-input v-model="editTargetInvoices.issued_date"></el-input>
    </el-form-item>
    <el-form-item label="Nhà cung cấp">
      <el-input v-model="editTargetInvoices.seller_name"></el-input>
    </el-form-item>
    <el-form-item label="Người mua">
      <el-input v-model="editTargetInvoices.buyer_name"></el-input>
    </el-form-item>
    <el-form-item label="Trạng thái">
      <el-input v-model="editTargetInvoices.approved_status_label"></el-input>
    </el-form-item>
  </el-form>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="showEditModal = false">
        {{ $t("button.huy") }}
      </el-button>
      <el-button type="primary"> {{ $t("button.xacNhan") }} </el-button>
    </span>
  </template>
</el-dialog>

<el-dialog
  title="Thêm hóa đơn"
  @closed="resetAddInvoices"
  v-model="showAddModal"
  :width="dialogWidth"
>
  <div class="flex justify-between items-center mx-auto" style="width: 800px">
    <div>{{files.length}} tệp ({{totalFileSize}} MB)</div>
    <div class="text-red-500" v-if="isBigSize">
      <span class="font-bold">*Tối đa: 20MB</span>
    </div>
  </div>
  <div class="text-center mx-auto" style="max-width: 800px">
    <div @dragover.prevent @drop.prevent>
      <input
        type="file"
        class="hidden"
        id="clickChoseFile"
        accept="application/pdf, text/xml, .zip, .rar, .inv "
        multiple
        @change="uploadFile"
      />
      <label
        for="clickChoseFile"
        @drop="dragFile"
        :class="{'border-err' : isBigSize }"
        class="
          mb-3
          w-full
          h-56
          overflow-y-auto
          md:px-5
          border-ddd
          mx-auto
          flex
          justify-center
          items-center
          rounded
          cursor-pointer
        "
      >
        <div v-if="!files.length">
          <label for="clickChoseFile" class="text-primary cursor-pointer">
            Chọn file
          </label>
          <span> hoặc kéo file vào đây!!!</span>
        </div>

        <div v-else class="block">
          <ul
            v-for="(file, index) in files"
            :key="index"
            @click="disableOpenAddFile($event)"
          >
            <li class="text-left">
              <div class="flex items-center hover-bold">
                <div class="col-span-3 my-1">{{ sliceStr(file.name) }}</div>
                <div>
                  <img
                    src="@/assets/icons/times.svg"
                    alt=""
                    class="ml-1 w-3 h-3 cursor-pointer"
                    @click="deleteFile($event,index)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </label>
    </div>

    <div class="itax-button text-right block mx-auto mt-5" style="max-width: 800px">
      <el-button size="mini" class="mt-3" @click="showAddModal = false">
        Hủy
      </el-button>
      <el-button
        size="mini"
        class="mt-3"
        :disabled="files.length <= 0 || isBigSize"
        type="primary"
        @click="upload()"
      >
        Xác nhận
      </el-button>
    </div>
  </div>
</el-dialog>
